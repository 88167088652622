import { Box, Text } from "@chakra-ui/react";
import * as React from "react";

export const FullPageErrorFallback = ({
  error,
}: {
  error: { message: string };
}) => {
  return (
    <Box
      role="alert"
      color="red.500"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text>Uh oh... There's a problem. Try refreshing the app.</Text>
      <Text as="pre">{error.message}</Text>
    </Box>
  );
};
