import { Box, Spinner } from "@chakra-ui/react";
import * as React from "react";

export const Loader = () => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );
};

export const FullPageLoader = () => {
  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Loader />
    </Box>
  );
};
