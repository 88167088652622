import * as React from "react";
import { FullPageLoader } from "components/Feedback";
import { useAuth } from "contexts/AuthContext";

const Authenticated = React.lazy(() => import("./Authenticated"));
const Unauthenticated = React.lazy(() => import("./Unauthenticated"));

export const App = () => {
  let auth = useAuth();

  return (
    <React.Suspense fallback={<FullPageLoader />}>
      {auth?.user ? <Authenticated /> : <Unauthenticated />}
    </React.Suspense>
  );
};
