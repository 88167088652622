import Cookies from "js-cookie";
import { User } from "shared/types";

export const authTokenKey = "__auth_token__";
export const successTicketListKey = "__ticket_list_success__";
export const failedTicketListKey = "__ticket_list_fail__";
export const akpResellerKey = "akp_rid";

export function getToken() {
  return Cookies.get(authTokenKey);
}

export function setToken(user: User) {
  // const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

  Cookies.set(authTokenKey, user.token, { expires: 1 });
  return user;
}

export function getSessionStorage(key: string) {
  const data = sessionStorage.getItem(key);
  if (data) return JSON.parse(data);
}

export function setSessionStorage(key: string, value: any) {
  if (getSessionStorage(key)) {
    sessionStorage.removeItem(key);
  }

  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key: string) {
  const data = localStorage.getItem(key);
  if (data) return JSON.parse(data);
}

export function setLocalStorage(key: string, value: any) {
  if (getLocalStorage(key)) {
    localStorage.removeItem(key);
  }

  localStorage.setItem(key, JSON.stringify(value));
}
