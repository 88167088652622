import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { useQueryClient } from "react-query";
import Cookies from "js-cookie";
import { FullPageErrorFallback, FullPageLoader } from "components/Feedback";
import { config } from "config";
import { useAsync } from "utils/hooks";
import {
  getToken,
  authTokenKey,
  setToken,
  akpResellerKey,
} from "utils/storage";

type UserInput = {
  email: string;
  password: string;
};

interface AuthContextProps {
  user: Object;
  login: ({ email, password }: UserInput) => Promise<any>;
  logout: () => void;
}

const { baseURL } = config;

async function client(endpoint: string, data: any) {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  };

  return window
    .fetch(`${baseURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

const AuthContext = React.createContext<AuthContextProps | null>(null);
AuthContext.displayName = "AuthContext";

export const AuthProvider = (props: any) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  React.useEffect(() => {
    async function bootstrapAppData() {
      let user = null;
      const token = await getToken();
      if (token) {
        // user = JSON.parse(token);
        user = token;
        const data = await window
          .fetch(`${baseURL}/common`)
          .then((res) => res.json());
        queryClient.setQueryData("common", data);
      }

      return user;
    }

    const appDataPromise = bootstrapAppData();
    run(appDataPromise);
  }, [queryClient, run]);

  const login = React.useCallback(
    (form) => {
      return client("admin/auth", form)
        .then(setToken)
        .then((user) => {
          window.localStorage.setItem(akpResellerKey, JSON.stringify(user));
          setData(user);
        })
        .catch((err) => {
          toast({
            title: "Login failed.",
            description: err.message,
            status: "error",
            duration: 6000,
            isClosable: true,
            position: "top-right",
          });
        });
    },
    [setData, toast]
  );

  const logout = React.useCallback(() => {
    Cookies.remove(authTokenKey);
    window.localStorage.clear();
    queryClient.clear();
    setData(null);
  }, [queryClient, setData]);

  const value = React.useMemo(
    () => ({ user, login, logout }),
    [login, logout, user]
  );

  if (isLoading || isIdle) {
    return <FullPageLoader />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
